<template>
  <v-dialog
    persistent
    :overlay-color="color_background"
    overlay-opacity="1"
    v-model="login_dialog"
    max-width="340"
  >
    <v-card
      elevation="24"
      min-height="400"
      max-width="340"
      class="rounded-xl mx-auto color_second"
    >
      <div
        v-if="
          email_enabled &&
          !restore_procedure &&
          !restore_procedure_ask_new_password
        "
      >
        <div style="height: 30px"></div>
        <v-card flat width="260" class="mx-auto">
          <v-row>
            <v-col cols="6" class="pa-1">
              <div
                :class="color_text_second + '--text'"
                class="text-9 text-center"
                style="position: relative; bottom: -7px"
              >
                по номеру телефона
              </div>
              <v-btn
                :elevation="button_elevation"
                :height="button_height"
                :color="!login_as_email ? color_main : color_second"
                :class="!login_as_email ? color_second + '--text' : ''"
                class="rounded-lg mt-2"
                block
                @click="
                  login_as_email = false;
                  login = '';
                "
              >
                я
              </v-btn>
            </v-col>
            <v-col cols="6" class="pa-1">
              <div
                :class="color_text_second + '--text'"
                class="text-9 text-center"
                style="position: relative; bottom: -7px"
              >
                по email магазина
              </div>
              <v-btn
                :elevation="button_elevation"
                :height="button_height"
                :color="login_as_email ? color_main : color_second"
                :class="login_as_email ? color_second + '--text' : ''"
                class="rounded-lg mt-2"
                block
                @click="
                  login_as_email = true;
                  login = '';
                "
              >
                мы
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
        <div style="height: 50px"></div>
      </div>
      <v-card-title class="pb-6">
        <div v-if="!error && !message" class="text-h5 ml-3 mt-3 grey4--text">
          Авторизация oboi.ru
        </div>
        <div
          class="message mx-auto mt-3"
          style="font-size: 14px"
          :class="error ? color_error + '--text' : color_text_second + '--text'"
        >
          {{ error || message }}
        </div>
      </v-card-title>
      <v-card-text>
        <div v-if="!restore_procedure && !restore_procedure_ask_new_password">
          <div v-if="login_as_email">
            <v-text-field
              :color="color_main"
              v-model.trim="login"
              placeholder=" "
              persistent-placeholder
              minlength="10"
              class="text-18 rounded-lg"
              name="email"
              label="email магазина"
              outlined
            ></v-text-field>
          </div>
          <div v-else>
            <masked-input
              v-show="false"
              type="text"
              name="phone"
              class="form-control"
              v-model="login"
              :mask="[
                '+7',
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                /\d/,
              ]"
              :guide="false"
              placeholderChar="#"
            >
            </masked-input>
            <v-text-field
              :color="color_main"
              v-model.trim="login"
              placeholder=" "
              persistent-placeholder
              :rules="rules_phone_input"
              maxlength="12"
              class="text-18 rounded-lg"
              name="phone"
              label="номер телефона"
              outlined
            ></v-text-field>
          </div>
          <v-text-field
            :append-icon="show_password ? 'mdi-eye' : 'mdi-eye-off'"
            :type="show_password ? 'text' : 'password'"
            :color="color_main"
            name="password"
            v-model.trim="password"
            hint="не меньше 8 символов"
            @click:append="show_password = !show_password"
            class="text-18 input-group--focused rounded-lg"
            label="пароль"
            placeholder=" "
            persistent-placeholder
            outlined
            v-on:keyup.enter="
              login.length >= min_len_login
                ? auth()
                : (error = 'короткий номер телефона')
            "
          ></v-text-field>
          <div>
            <v-btn
              :elevation="button_elevation"
              :height="button_height"
              :color="color_main"
              :class="color_text_main + '--text'"
              block
              class="rounded-lg"
              @click="
                login.length >= min_len_login
                  ? auth()
                  : (error = 'короткий номер телефона')
              "
            >
              войти
            </v-btn>
            <v-btn
              :disabled="login_as_email"
              :elevation="button_elevation"
              :height="button_height"
              :color="color_second"
              :class="color_text_second + '--text'"
              block
              class="rounded-lg mt-2"
              @click="
                login.length >= min_len_login
                  ? restore()
                  : (error = 'логин короче необходимого')
              "
            >
              хочу новый пароль
            </v-btn>
          </div>
        </div>
        <div v-else>
          <div v-if="restore_procedure">
            <div class="mb-5 text-center">
              <div class="text-24 grey--text">
                +7 (958) 201 <span class="black--text">XXXX</span>
              </div>
            </div>
            <v-text-field
              class="text-18 rounded-lg"
              :color="color_main"
              label="последние 4 цифры номера"
              v-model="restore_code"
              outlined
              autofocus
            ></v-text-field>
            <v-btn
              :elevation="button_elevation"
              :height="button_height"
              block
              :color="color_main"
              :class="color_text_main + '--text'"
              :disabled="time.length > 0"
              class="rounded-lg"
              @click="restore()"
            >
              <span> повторить звонок </span>
              <span v-if="time"> через {{ time }} сек </span>
            </v-btn>
          </div>

          <div v-if="restore_procedure_ask_new_password">
            <v-text-field
              :append-icon="show_password ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show_password ? 'text' : 'password'"
              :color="color_main"
              name="password"
              v-model="password"
              hint="не меньше 8 символов"
              @click:append="show_password = !show_password"
              class="text-18 input-group--focused rounded-lg"
              label="новый пароль"
              outlined
              autofocus
              v-on:keyup.enter="change_pass()"
            ></v-text-field>

            <v-btn
              :elevation="button_elevation"
              :height="button_height"
              :color="color_main"
              :class="color_text_main + '--text'"
              block
              class="rounded-lg"
              :disabled="password.length < 8"
              @click="change_pass()"
            >
              задать пароль
            </v-btn>
          </div>
          <v-btn
            :elevation="button_elevation"
            :height="button_height"
            :color="color_second"
            :class="color_text_second + '--text'"
            class="rounded-lg mt-2"
            block
            @click="cancel()"
          >
            отмена
          </v-btn>
        </div>

        <div
          style="font-size: 8px"
          class="bottom mx-auto grey--text text--lighten-1"
        >
          {{ version_component }}
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import MaskedInput from "vue-text-mask";
export default {
  name: "Login",
  components: {
    MaskedInput,
  },
  template: "button-template",
  props: {
    login_dialog: {
      type: Boolean,
      default: false,
      required: true,
    },
    email_enabled: {
      type: Boolean,
      default: false,
    },
    button_elevation: {
      type: String,
      default: "1",
    },
    button_height: {
      type: String,
      default: "50",
    },
    color_background: {
      type: String,
      default: "black",
    },
    color_main: {
      type: String,
      default: "black",
    },
    color_text_main: {
      type: String,
      default: "white",
    },
    color_second: {
      type: String,
      default: "grey",
    },
    color_text_second: {
      type: String,
      default: "black",
    },
    color_error: {
      type: String,
      default: "red--text",
    },
  },
  data() {
    return {
      version_component: "05/2022", //версия компонента
      rules_phone_input: [(v) => v.length <= 12 || "слишком длинный номер"],
      show_password: false, //показывать пароль а не звездочки

      time: "", //время смс
      error: undefined, //ошибки авторизации
      message: undefined, //сообщеня авторизации (не ошибки)

      restore_procedure: false, //восстановление пароля, ждем смс
      restore_procedure_ask_new_password: false, //восстановление пароля, новый пароль

      restore_code: "", //код из смс
      min_len_login: 11, //минимальная длина логина
      login_as_email: false, //логинимся через email магазина
      login: " ", //логин
      password: "", //пароль
    };
  },

  watch: {
    login_as_email() {
      if (this.login_as_email) this.min_len_login = 10;
      else if (!this.login_as_email) this.min_len_login = 12;
    },
    login() {
      this.login = this.login.replace("+78", "+7");
      this.login = this.login.replace("+77", "+7");
    },
    restore_code() {
      if (this.restore_code.length == 4) {
        this.restore_procedure = false;
        this.restore_procedure_ask_new_password = true;
        this.clear_message();
        this.show_password = true;
        this.message = "задать новый пароль";
      }
    },
    time() {
      if (this.time == "00") this.time = "";
    },
  },
  methods: {
    finish() {
      this.clear_message();
      this.cancel();
      this.$emit("finish");
    },
    cancel() {
      this.restore_procedure = false;
      this.restore_code = "";
      this.restore_procedure_ask_new_password = false;
      this.clear_message();
      this.password = undefined;
    },
    clear_message() {
      this.error = undefined;
      this.message = undefined;
    },

    timer(seconds) {
      //считаем время
      this.time = ("0" + seconds).slice(-2);
      let time = setInterval(() => {
        seconds = seconds - 1;
        this.time = ("0" + seconds).slice(-2);
        if (seconds == 0) clearInterval(time);
      }, 1000);
    },

    set_login_items(json) {
      //проверка ответа авторизации
      if (json["access_token"]) {
        localStorage.setItem("access_token", json["access_token"]);
      }
      if (json["refresh_token"]) {
        localStorage.setItem("refresh_token", json["refresh_token"]);
      } else this.error = json.response;
    },

    async fetch_check_status(response) {
      if (response.status !== 200) {
        if (response.status === 500)
          this.error = "😵 ошибка на сервере: " + response.url;
        if (response.status === 404)
          this.error = "😵 ошибка в адресе: " + response.url;
        if (response.status === 401) {
          this.error = (await response.json()).response;
        }
        return Promise.reject(response.status);
      }
      return Promise.resolve(response);
    },

    fetch_get_data(response) {
      return response.json();
    },

    async auth() {
      //авторизация
      this.clear_message();
      fetch("https://auth.oboi.ru/token/login", {
        method: "POST",
        body: JSON.stringify({
          username: this.login,
          password: this.password,
        }),
      })
        .then(this.fetch_check_status)
        .then(this.fetch_get_data)
        .then((data) => {
          this.set_login_items(data);
          this.finish();
        })
        .catch(function (error) {
          console.log("async auth() ::: catch error", error);
        });
    },

    async restore() {
      //запуск процедуры восстановления пароля
      this.timer(59);
      this.restore_procedure = true;
      this.clear_message();
      this.password = "";
      fetch("https://auth.oboi.ru/sms/restore_pass", {
        method: "POST",
        body: JSON.stringify({ username: this.login }),
      })
        .then(this.fetch_check_status)
        .then(this.fetch_get_data)
        .then((data) => {
          console.log("restore_pass", data);
          this.message = data.response;
        })
        .catch(function (error) {
          console.log("async restore() ::: catch error", error);
        });
    },

    async change_pass() {
      fetch("https://auth.oboi.ru/sms/change_pass", {
        method: "POST",
        body: JSON.stringify({
          username: this.login,
          pass: this.password,
          code: this.restore_code,
        }),
      })
        .then(this.fetch_check_status)
        .then(this.fetch_get_data)
        .then((data) => {
          this.set_login_items(data);
          this.finish();
        })
        .catch(function (error) {
          console.log("async change_pass() ::: catch error", error);
        });
    },
  },
  mounted() {},
  created() {},
};
</script>

<style scoped>
.bottom {
  position: absolute;
  bottom: 1px;
}
</style>

import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../components/Home.vue'
import InformationBitrix from "../components/InformationBitrix.vue";
import Guide from "../components/Guide.vue";
import InformationAmo from "../components/InformationAmo.vue";
import Terms from "../components/Terms.vue";
import Wealth from "../components/Wealth.vue";
import CoordinatorBook from "../components/CoordinatorBook.vue";
import Factories from "../components/Factories.vue";
import TimeTracking from "../components/TimeTracking.vue";
import Delays from "../components/Delays.vue";


Vue.use(VueRouter)

const routes = [
{
    path: '/',
    name: 'Home',
    component: Home
  },
  { 
    path: '/informationbitrix',
    name: 'InformationBitrix',
    component: InformationBitrix
  },
  { 
    path: '/guide',
    name: 'Guide',
    component: Guide
  },
  { 
    path: '/informationamo',
    name: 'InformationAmo',
    component: InformationAmo
  },
  { 
    path: '/terms',
    name: 'Terms',
    component: Terms
  },
  { 
    path: '/wealth',
    name: 'Wealth',
    component: Wealth
  },
  { 
    path: '/coordinatorbook',
    name: 'CoordinatorBook',
    component: CoordinatorBook
  },
  { 
    path: '/factories',
    name: 'Factories',
    component: Factories
  },
  { 
    path: '/timetracking',
    name: 'TimeTracking',
    component: TimeTracking
  },
  { 
    path: '/delays',
    name: 'Delays',
    component: Delays
  }
]

const router = new VueRouter({
  routes
})

export default router

<template >
  <v-row class="Panel mt-16">
    <v-col md=2 cols=4 v-for="l in card_link" :key="l.name">
      <Button :href="l.rout" target="_blank" :to="l.href">
        <template v-slot:icon>
          <v-icon>{{l.icon}}</v-icon>
        </template>
      </Button>
      <div>{{l.name}}</div>
    </v-col>
  </v-row>
</template>

<script>
import Button from "./ButtonLink"
  export default {
    name: 'PanelWithButton',
    components: {
      Button,
    },


    data: () => ({
      card_link : [
          {
            name:'Битрикс24', 
            description:'логин - ваша рабочая почта, пароль придумываете сами. Забыли пароль? Восстаналиваем через "восстановить пароль',
            icon:'filter_drama',
            rout: 'https://mnogooboev.bitrix24.ru',
          },
          // {
          //   name:'amoCRM', 
          //   description:'логин - ваша рабочая почта, пароль - amocrm123321',
          //   icon:'computer',
          //   rout: 'https://mnogooboev.amocrm.ru',
          // },
          {
            name:'1C опт', 
            description:'',
            icon:'star',
            rout: 'https://office.oboi.ru/1_ut_opt/ru_RU/'
          },
          {
            name:'Почта', 
            description:'',
            icon:'mail',
            rout: 'https://mail.ru'
          },
          {
            name:'1C розница', 
            description:'', 
            icon:'light_mode',
            rout: 'https://office.oboi.ru/ut_oboi/ru_RU/'
          },
          {
            name:'Файлы и документы', 
            description:'',
            icon:'layers',
            rout: 'https://office.oboi.ru'
          },
          {
            name:'Сканер документов', 
            description:'',
            icon:'highlight',
            rout: 'https://scanners.service.oboi.ru/'
          },
          // {
          //   name:'Фабрики',
          //   description:'',
          //   icon:'account_balance',
          //   href: 'factories'
          // },
          {
            name:'YouTube НБ', 
            description:'',
            icon:'video_camera_back',
            rout: 'https://www.youtube.com/channel/UCGq275nwgOxybDAN-4z9Mmg',
          },
          {
            name:'YouTube OBOI.RU', 
            description:'',
            icon:'switch_camera',
            rout: 'https://www.youtube.com/channel/UCImrGGMbYJJSEcA7VY0mETQ'
          },
          // {
          //   name:'График работы', 
          //   description:'',
          //   icon:'schedule',
          //   href: 'delays'
          // },
          {
            name:'Маркетплейсы', 
            description:'',
            icon:'shopping_cart',
            rout: 'https://start.oboi.ru/marketplace'
          },
          {
            name:'Регламенты', 
            description:'',
            icon:'menu_book',
            rout: 'https://startoboi.ru/03-docs/book2/'
          },
          {
            name:'Контент', 
            description:'',
            icon:'mms',
            rout: 'https://disk.yandex.ru/d/DIh_xeict3iNMA'
          },

          {
            name:'Сотрудники', 
            description:'',
            icon:'face_retouching_natural',
            rout: 'https://docs.google.com/spreadsheets/d/1jBGdc-s2IvntLV6w7irk5cvS0EzMhvsbmIco4tengFw/pubhtml?gid=201071489&single=true'
          }
          ],

      
    }),
    }
</script>


<style scoped>
.Panel {
  background: #cacaca;
  box-shadow: inset 1px 1px 2px rgba(238, 238, 238, 0.3), inset -1px -1px 2px rgba(194,194,194,0.5),-3px 3px 6px rgba(194, 194, 194, 0.2), 3px -3px 6px rgba(194, 194, 194, 0.2), -3px -3px 6px rgba(238, 238, 238, 0.9), 3px 3px 8px rgba(194, 194, 194, 0.9);
  border-radius: 5px;
  margin: 10px 10px 0px 10px;
  text-align: center;
  color: #ffffff;
}

</style>

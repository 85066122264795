<template >
  <v-main >
    <div class="text-center Title">
      <v-btn color="grey" icon x-large to="/">
        <v-icon>arrow_back</v-icon>
      </v-btn>
        График работы
    </div>

    <v-btn
      fab
      text
      small
      color="grey darken-2"
      @click="prev">
        <v-icon small>
          mdi-chevron-left
        </v-icon>
    </v-btn>
    <v-btn
      fab
      text
      small
      color="grey darken-2"
      @click="next">
        <v-icon small>
          mdi-chevron-right
        </v-icon>
    </v-btn> 
    <span> октябрь, 41нед</span>


    <v-simple-table :style="cardinfo">
      <template v-slot:default>
        <thead>
          <tr>
            <th>ФИО</th>
            <th class="text-center" v-for="dweek in dayweek" :key="dweek">
              {{dweek}}
              <v-row>
                <v-col>пришел</v-col>
                <v-col>ушел</v-col>
                <v-col>общее</v-col>
              </v-row> 
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="i in 10"
            :key="i"
            >
            <td >Иван Иванов</td>
            <td class="text-center" v-for="k in 5" :key="k">
            <v-row>
              <v-col>8:59</v-col>
              <v-col>18:00</v-col>
              <v-col>8:01</v-col>
            </v-row>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </v-main>   
</template>

<script>


export default {
    name: 'Delays',

    data: () => ({
      src: "https://251317.selcdn.ru/oboi.ru/brand/test.png",
      cardinfo: { 'background': '#F2F2F2',
                  'box-shadow': 'inset 1px 1px 2px rgba(255, 255, 255, 0.3), inset -1px -1px 2px rgba(219,219,219,0.5),-10px 10px 10px rgba(219, 219, 219, 0.2), 5px -5px 10px rgba(219, 219, 219, 0.2), -5px -5px 10px rgba(255, 255, 255, 0.9), 5px 5px 13px rgba(219, 219, 219, 0.9)',
                  'border-radius': '15px',
                  'border':'1px solid rgba(69,86,122,0.005)',
                  'margin-top':'30px'},
      dayweek: ['10окт','11окт','12окт','13окт','14окт','15окт','16окт'],
    }),
    }
</script>


<style scoped>
 iframe {
    margin: 0 auto;
    display: block;
}

.Title {
  font-size: 50px;
  color: #616161;
  font-weight: 400;
  margin-top: 40px;
}

.Blocks {
/*  margin-top: 30px;
  margin-bottom: 30px;*/
  padding: 30px;
}

</style>

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import "./scss/main.scss";
import axios from "axios";
import createAuthRefreshInterceptor from "axios-auth-refresh";

let count_req = 0;
// console.log(count_req);

// INTERCEPTORS
axios.interceptors.request.use((request) => {
  count_req++;
  // убери если все адреса почищены
  request.url = request.url.replace(process.env.VUE_APP_BACK_DOMEN, "");
  if (process.env.VUE_APP_BACK_DOMEN && !request.url.includes("http"))
    request.url = process.env.VUE_APP_BACK_DOMEN + request.url;
  if (store.state.loading === false) store.state.loading = true;
  // console.log(request)
  if (!request.url.includes("refresh"))
    request.headers["Authorization"] = `Bearer ${localStorage.getItem(
      "access_token"
    )}`;
  if (typeof request.data == "string") {
    request.data = JSON.parse(request.data);
  }
  if (store.state.shop) {
    if (!request.data || !request.data.shopID)
      request.data = { ...request.data, shopID: store.state.shop };
  }
  // console.log(request, count_req);
  return request;
});

axios.interceptors.response.use(
  (res) => {
    count_req--;
    // console.log(res, count_req);
    if (count_req <= 0) store.state.loading = false;
    return res;
  },
  (err) => {
    count_req--;
    // console.log(err.response);
    if (count_req <= 0) store.state.loading = false;
    if (
      [401, 422].includes(err.response && err.response.status) &&
      err.response.config.url.includes("refresh")
    ) {
      return;
    }
    // console.log(err.response.config.url);
    return Promise.reject(err);
  }
);

const refreshAuthLogic = (failedRequest) =>
  axios
    .post(
      "https://auth.oboi.ru/token/refresh",
      {},
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("refresh_token"),
          Access_token: localStorage.getItem("access_token")
            ? localStorage.getItem("access_token")
            : "",
        },
      }
    )
    .then((tokenRefreshResponse) => {
      if (!tokenRefreshResponse) return Promise.reject(failedRequest);
      localStorage.setItem(
        "access_token",
        tokenRefreshResponse.data.access_token
      );
      store.commit("change_access", tokenRefreshResponse.data.access_token);
      failedRequest.response.config.headers["Authorization"] =
        "Bearer " + tokenRefreshResponse.data.access_token;
      return Promise.resolve();
    })
    .catch((err) => {
      count_req--;
      // console.log(count_req);
      // console.log('здесь', err);
      localStorage.removeItem("access_token");
      localStorage.removeItem("refresh_token");
      store.commit("login_on");
      return Promise.reject(err);
      // router.push({ name: "Login" });
    });

createAuthRefreshInterceptor(axios, refreshAuthLogic, {
  statusCodes: [401, 422],
});

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");

import Vue from "vue";
import Vuex from "vuex";
import VueJwtDecode from "vue-jwt-decode";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    login_show: false,
    jwt_domain: process.env.VUE_APP_JWT_DOMAIN || document.domain,
    username: localStorage.getItem("access_token")
      ? VueJwtDecode.decode(localStorage.getItem("access_token")).identity
      : "", // имя пользователя
    fullname: localStorage.getItem("access_token")
      ? VueJwtDecode.decode(localStorage.getItem("access_token")).user_claims
          ?.name
      : "",
    access: localStorage.getItem("access_token")
      ? {
          ...(VueJwtDecode.decode(localStorage.getItem("access_token"))
            ?.user_claims?.[
            process.env.VUE_APP_JWT_DOMAIN || document.domain
          ] || {}),
          root: VueJwtDecode.decode(localStorage.getItem("access_token"))
            .user_claims?.root,
        }
      : {}, // права пользователя
  },
  mutations: {
    login_on(state) {
      state.login_show = true;
    },

    login_off(state) {
      state.login_show = false;
    },
  },
  actions: {},
  modules: {},
});

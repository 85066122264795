<template>
  <v-app id="App">
    <Header />
    <ParallaxCard />
    <Login
      v-if="login_show"
      @finish="close_login_dialog"
      :login_dialog="login_show"
      color_background="white"
      color_main="orange"
      color_text_main="white"
      color_second="white"
      color_text_second="orange"
      button_height="50"
    />

    <v-main>
      <v-container>
        <router-view />
      </v-container>
    </v-main>
    <div class="grey--text text-center" style="font-size: 8px">
      {{ username }}
      {{ fullname }}
      {{ access }}
    </div>
  </v-app>
</template>

<script>
import Login from "./components/Login2022";
import Header from "./components/Header";
import ParallaxCard from "./components/ParallaxCard";
import { mapState, mapMutations } from "vuex";

export default {
  name: "App",
  components: {
    Header,
    ParallaxCard,
    Login,
  },

  data: () => ({
    dialog: false,
  }),
  computed: {
    ...mapState(["login_show", "username", "fullname", "access"]),
  },
  methods: {
    ...mapMutations(["login_on", "login_off"]),

    close_login_dialog() {
      this.login_off();
      this.$router.go(this.$router.currentRoute);
    },
  },
  created: function () {
    let refresh = localStorage.getItem("refresh_token");
    let access = localStorage.getItem("access_token");

    if (!refresh || !access) {
      this.login_on();
    }
  },
};
</script>

<template >
  <v-main >
    <div class="text-center Title">
      <v-btn color="grey" icon x-large to="/">
        <v-icon>arrow_back</v-icon>
      </v-btn>
        Словарь терминов
    </div>

    <iframe src="https://docs.google.com/document/d/e/2PACX-1vQiRZ7DlyUbI5JuM1eW6WzMhWG_sRGHKOVRvRmXAh0S_t1VZXGAa8JuhH5tixr0ULn4KK8ptI7BVyLH/pub?embedded=true" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" :style="cardinfo"></iframe>
<!--  <v-btn fab class="BottonFixed">
   <v-icon color="grey" size="30">arrow_upward</v-icon>
 </v-btn> -->
  </v-main>   
</template>

<script>


export default {
    name: 'Terms',

    data: () => ({
      src: "https://251317.selcdn.ru/oboi.ru/brand/test.png",
      cardinfo: { 'background': '#FFFFFF',
                  'box-shadow': 'inset 1px 1px 2px rgba(255, 255, 255, 0.3), inset -1px -1px 2px rgba(219,219,219,0.5),-10px 10px 10px rgba(219, 219, 219, 0.2), 5px -5px 10px rgba(219, 219, 219, 0.2), -5px -5px 10px rgba(255, 255, 255, 0.9), 5px 5px 13px rgba(219, 219, 219, 0.9)',
                  'border-radius': '15px',
                  'border':'1px solid rgba(69,86,122,0.005)',
                  'margin-top':'30px'},
    }),
   
    }
</script>


<style scoped>
 iframe {
    width: 790px;
    height: 4550px;
    margin: 0 auto;
    display: block;
}

.Title {
  font-size: 50px;
  color: #616161;
  font-weight: 400;
  margin-top: 40px;
}


.BottonFixed {
display: block;
width: 80px;
height: 150px;
position: fixed;
right: 150px;
top: 500px;
z-index: 99999;
transition: all .3s ease-in-out;
}


</style>

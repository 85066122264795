<template >
  <v-main >
    <div class="text-center Title">
      <v-btn color="grey" icon x-large to="/">
        <v-icon>arrow_back</v-icon>
      </v-btn>
        Инструкции по работе в amoCRM
    </div>
      <div class="Menu">
        <div><a href="#taskManagement" style="color: #5c6bc0">Управление задачами</a></div>
        <div><a href="#taskDisplay" style="color: #5c6bc0">Отображение задач</a></div>
        <div><a href="#taskSort" style="color: #5c6bc0">Сортировка задач</a></div>
        <div><a href="#motivation" style="color: #5c6bc0">Работа с задачами. Мотивация</a></div>
        <div><a href="#newClient" style="color: #5c6bc0">Заведение нового клиента или контактного лица </a></div>
        <div><a href="#funnels" style="color: #5c6bc0">Воронки продаж</a></div>
        <div><a href="#sortClient" style="color: #5c6bc0">Поиск, сортировка и отбор клиентов. Раздел "Списки". </a></div>
        <div><a href="#analytics" style="color: #5c6bc0">Аналитика</a></div>
        <div><a href="#callCounter" style="color: #5c6bc0">Счетчики звонков</a></div>
        <div><a href="#plans" style="color: #5c6bc0">Занесение планов</a></div>
        <div><a href="#appeal" style="color: #5c6bc0">Обработка обращений</a></div>
      </div>
      <div class="Main" :style="cardinfo">
        <span class="Subtitle"> 
          <a name="taskManagement" style="color: #5c6bc0">
            Управление задачами.
          </a>
        </span>
          <span><b>Постановка задачи на клиента.</b></span>
          <span>Поставить задачу можно двумя способами:
            <ol>
              <li>в карточке клиента</li>
              <li>в разделе "Задачи"</li>
            </ol>
          </span>
          <span><b>Рассмотрим первый способ.</b></span>
          <span>Чтобы зайти в карточку клиента, идем в раздел "Списки", в строку поиска вводим название компании, из найденных партнеров выбираем нужного.</span>
            <img alt="" src="https://251317.selcdn.ru/oboi.ru/BlogSrc/imagesA/image14.png" >
            <span>В карточке клиента в основном окне снизу видим текстовое поле. Из выпадающего списка выбираем "Задача". Далее выбираем срок исполнения, исполнителя и категорию задачи.</span>

            <img alt="" src="https://251317.selcdn.ru/oboi.ru/BlogSrc/imagesA/image26.png" >
            <span>На выбор есть несколько категорий задач. Из списка выбираем максимально подходящую. Добавлять категорию самому нельзя. Содержание задачи описывать максимально подробно. Например, если в планах предлагать новинки, то пишем какие именно новинки будете предлагать.</span>

            <img alt="" src="https://251317.selcdn.ru/oboi.ru/BlogSrc/imagesA/image46.png">
            <span>Задача отобразится в основном окне.</span>
            <img alt="" src="https://251317.selcdn.ru/oboi.ru/BlogSrc/imagesA/image44.png">
          <span>Можно поставить несколько задач одновременно, тогда они будут отображаться списком. Задачи на сегодня выделяются зеленым цветом, на будущее - серым, а просроченные - красным.</span>
            <img alt="" src="https://251317.selcdn.ru/oboi.ru/BlogSrc/imagesA/image37.png">
          <span>Если задача выполнена, открываем ее, и обязательно пишем результат выполнения прежде чем закрыть задачу.</span>
            <img alt="" src="https://251317.selcdn.ru/oboi.ru/BlogSrc/imagesA/image23.png">
          <span>Если задача не выполнена в срок по какой-либо причине или выполнена не до конца, задачу все равно закрываем и ставим новую. Для этого открываем задачу, пишем причину невыполнения задачи в указанный срок или промежуточный результат, ставим галочку напротив поля "Связаться с клиентом" и выбираем дату следующего звонка. Нажимаем "Выполнить".</span>
            <img alt="" src="https://251317.selcdn.ru/oboi.ru/BlogSrc/imagesA/image12.png">
          <span>Задача будет закрыта и продублирована с новым сроком выполнения.</span>
            <img alt="" src="https://251317.selcdn.ru/oboi.ru/BlogSrc/imagesA/image19.png">
          <span>Изменяем поставленную задачу</span>
            <img alt="" src="https://251317.selcdn.ru/oboi.ru/BlogSrc/imagesA/image27.png">
          <span>Изменяем выполненную задачу или комментарий</span>
            <img alt="" src="https://251317.selcdn.ru/oboi.ru/BlogSrc/imagesA/image52.png">
          <span>Рассмотрим второй способ постановки, он более универсальный.
          Для этого переходим в раздел "Задачи". Для постановки задачи нажимаем "Добавить задачу".
          </span>
            <img alt="" src="https://251317.selcdn.ru/oboi.ru/BlogSrc/imagesA/image28.png">
          <span>Выбираем срок выполнения задачи, исполнителя, категорию, вводим текст задачи. Также нужно выбрать контактное лицо, на которое ставится задача (не компанию), или сделку (например план на месяц). Для этого в поиске набираем название компании, и программа показывает все контактные лица данного клиента и сделки, поставленные на него. Если у клиента нет контактного лица или сделок, то поставить задачу данным способом не получится. Тут есть два варианта решения: завести контактное лицо для клиента (что более практично) или поставить задачу напрямую из карточки клиента (1 способом)
          </span>
            <img alt="" src="https://251317.selcdn.ru/oboi.ru/BlogSrc/imagesA/image51.png">
            <span>Поставленная задача сразу отобразится в списке задач исполнителя.
          Для того, чтобы завершить задачу, кликаем на нее, проваливаемся в карточку клиента и завершаем, как уже было описано выше.
          </span>
          <span><b>Постановка задачи общего типа</b></span>
          <span>Есть возможность поставить общую задачу, не привязанную к конкретному клиенту (например "написать отчет о планируемых отгрузах на неделю"). Для этого заходим в раздел "Задачи" и через быстрое добавление ставим задачу так же, как было описано выше. Разница будет только в том, что указывать клиента не нужно.
          </span>

          <img alt="" src="https://251317.selcdn.ru/oboi.ru/BlogSrc/imagesA/image2.png">
          <span>Чтобы выполнить, удалить или перенести такую задачу, необходимо перетащить ее в Корзину, Завершить или в соседнюю колонку соответственно.
          </span>
          <img alt="" src="https://251317.selcdn.ru/oboi.ru/BlogSrc/imagesA/image56.png">
          <span>В случае завершения задачи нужно записать результат выполнения.</span>
          <img alt="" src="https://251317.selcdn.ru/oboi.ru/BlogSrc/imagesA/image54.png">
        <span class="Subtitle">
          <a name="taskDisplay" style="color: #5c6bc0">
            Отображение задач
          </a>
        </span>
        <span>С задачами можно работать в разрезе одного дня, недели и месяца.
        Задачи на день распределяются по времени. Если у задачи время выполнения не установлено, они отображаются в поле "Весь день". Задачи в пределах дня можно переставлять на заданное время путем перетаскивания.
        </span>
        <img alt="" src="https://251317.selcdn.ru/oboi.ru/BlogSrc/imagesA/image34.png">
        <span>Смотрим задачи на неделю.</span>
        <img alt="" src="https://251317.selcdn.ru/oboi.ru/BlogSrc/imagesA/image40.png">
        <span>и на месяц.</span>
        <img alt="" src="https://251317.selcdn.ru/oboi.ru/BlogSrc/imagesA/image29.png">
        <span>Также доступно два варианта отображения задач.
        Отображение в виде списка. Сначала отображаются задачи на сегодня, затем на завтра и на будущее.
        </span>
        <img alt="" src="https://251317.selcdn.ru/oboi.ru/BlogSrc/imagesA/image3.png">
        <span>
        Отображение задач в колонках, здесь отображаются только задачи на сегодня, на завтра и просроченные задачи.
        </span>
        <img alt="" src="https://251317.selcdn.ru/oboi.ru/BlogSrc/imagesA/image55.png">
      <span class="Subtitle">
        <a name="taskSort" style="color: #5c6bc0">
          Сортировка задач
        </a>
      </span>
        <span>Задачи можно сортировать и отбирать по заданным критериям. Для этого используется фильтр. По умолчанию на панели задач отображаются незавершенные задачи, в которых пользователь является исполнителем. С помощью фильтра можно отобрать задачи конкретного исполнителя или группы исполнителей, конкретного автора, созданные за определенный период времени, выполненные, просроченные. Также можно в один клик показать все свои просроченные или выполненные задачи.
        </span>
          <img alt="" src="https://251317.selcdn.ru/oboi.ru/BlogSrc/imagesA/image61.png">
        <span>Рассмотрим настройку фильтра на конкретном примере. Например, мне нужно посмотреть завершенные задачи типа "Связаться с клиентом" по группе менеджеров. Для этого я иду в фильтр и выбираю соответствующие параметры.</span>
          <img alt="" src="https://251317.selcdn.ru/oboi.ru/BlogSrc/imagesA/image57.png">
        <span>Вот что у меня получилось</span>
          <img alt="" src="https://251317.selcdn.ru/oboi.ru/BlogSrc/imagesA/image53.png">
        <span>В результате мы получаем завершенные задачи типа "Связаться с клиентом" у конкретных исполнителей.</span>
          <img alt="" src="https://251317.selcdn.ru/oboi.ru/BlogSrc/imagesA/image18.png">
      <span class="Subtitle">
        <a name="motivation" style="color: #5c6bc0">
          Работа с задачами. Мотивация.
        </a>
      </span>
      <span><b>Общие положения.</b>
        <ol>
          <li>Процент просроченных задач не превышает 10%. Просроченные задачи делятся на просроченные, выполненные и просроченные открытые.Просроченная открытая задача — еще не закрытая задача, считается просроченной, если была просрочена хотя бы на минуту, и даже если после была перенесена на другую дату — все равно считается просроченной. Просроченная выполненная задача — уже закрытая задача, которая была просрочена хотя бы на минуту на стадии открытой, и при закрытии так и остается просроченной. Итог: не допускаем просроченных задач, а для этого все задачи от руководства или поставленные автоматикой должны быть выполнены вовремя, задачи самим себе вовремя перепланированы.</li>
          <li>Изменения поставленных руководителем или автоматикой задач запрещены. Под изменением понимаем изменение типа, содержания, срока закрытия задачи. Задачи самому себе можно изменять по потребности.</li>
          <li>Все задачи должны быть закрыты с результатом. Все задачи без результата не считаются выполненными. 4. Для задач выбираем соответствующие типы задач. 5. Минимальное число задач самому себе = количество клиентов в плане.</li>
        </ol>
      </span>
      <span><b>Используемые типы задач и их описание.</b><br>Типы выбираем исходя из сути задачи и действия, которое необходимо сделать. Все действия у нас зачастую сводятся к звонкам клиенту, но в типе задачи мы конкретизируем с какой целью будет сделан тот или иной звонок.
        <ol>
          <li>Поиск новых клиентов. Этот тип выбираем для всех действий, связанных с поиском новых клиентов. Преимущественно используем для планирования проработки территории. Примером таких задач могут служить: проработка региона N, прозвон региона, поиск в интернете клиентов в регионе и т.д.</li>
          <li>Работа с новыми клиентами. Этот тип используем для работы с воронкой продаж "Новые клиенты". Сюда попадают все действия, связанные с началом сотрудничества.</li>
          <li>Работа с ПДЗ. Используем для всех действий, связанных с мероприятиями по сбору долгов: предупреждения и напоминания, запросы гарантийных писем и пр.</li>
          <li>Новинки. Используем для всех действий, связанных с расстановкой и предложением новинок клиенту. Примерами будут отправки презентаций, каталогов, предложения по телефону и пр.</li>
          <li>Документы. Используем для действий, связанных со сбором документов от клиента: УПД, договоров, соглашений, возвратных документов, актов сверки, фотографий и пр.</li>
          <li>Отчеты. Используем в основном для планирования дня, для действий, связанных с заполнением каких-либо отчетов для руководителя или клиента (продажи, остатки, рейтинги и пр.)</li>
          <li>Отгрузка. Используем для всех действий и звонков, связанных с получением заказа и его отгрузкой.</li>
          <li>Встреча. Используем для всех действий, связанных с личной встречей с клиентом и подготовке к ней. Сюда же включаем написание планов командировок для себя или РТП.</li>
          <li>Связаться с клиентом. Используем для звонков, суть которых не подходит ни к одному типу. Примером таких действий могут быть звонки с целью налаживания и поддержания отношений, уточнения какой-либо информации у клиента и пр.</li>
        </ol>
        Типы задач "Перезвонить" и "Другое" не используем.</span>

        <span><b>Планирование и оповещения.</b>
        Срок выполнения задачи — это время, до которого задача должна быть закрыта, а не срок начала ее выполнения. Поэтому необходимо просматривать задачи в разрезе недели.
        </span>
         <img alt="" src="https://251317.selcdn.ru/oboi.ru/BlogSrc/imagesA/image58.png" >
        <span>На скриншоте показана вся неделя по дням и все задачи на неделю. Переносить задачи со дня на день можно путем простого перетаскивания (не касается задач, поставленных руководителем). Если задача висит в разделе "Весь день" — значит у Вас есть целый день на то, чтобы ее завершить — не успели задача станет просроченной. Если задача находится в блоках со временем, то это время следует считать временем, до которого следует выполнить задачу, если не успели — задача станет просроченной. Потому в начале рабочего дня следует оценить как сегодняшний день, так и всю неделю, чтобы понимать какие задачи к какому сроку нужно выполнить и правильно расставлять приоритеты. Отсюда так же можно добавлять задачи и сразу их растаскивать по дням.<br>

        В АМО предусмотрены оповещения на электронную почту.
        Как работают:
          <ol>
            <li>если задача была создана и поставлена на другого пользователя, то ему сразу же придет оповещение о поставленной задаче.</li>
            <li> если задача не на сегодняшний день, а на будущее, то исполнителю за 5 минут до истечения срока приходит письмо на почту. Поясняю, если задача ставится на Завтра на весь день, то оповещение приходит утром, а если ставится на время, то за 5 минут до наступления времени. Цель оповещений: не чтобы напомнить, что есть такая задача, а чтобы пользователь не забыл закрыть ее вовремя.</li>
          </ol> <br>Если задача была создана сегодня со сроком сегодня весь день — оповещение не приходит.
          <br>Также стоит обращать внимание на уведомления в программе. Находятся они в левом нижнем углу.
        </span>
         <img alt="" src="https://251317.selcdn.ru/oboi.ru/BlogSrc/imagesA/image24.png">
        <span><b>Примерный алгоритм планирования и работы.</b>
          <ol>
            <li>В начале недели выбираю клиентов для проработки на неделю и ставлю по ним задачи.</li>
            <li>Распределяю эти задачи на неделю.</li>
            <li>В начале рабочего дня просматриваю свой план и определяю примерную очередность задач на сегодня. Первым делом буду выполнять задачи, которые мне поставил руководитель, потом те, которые поставила система, затем те, которые я сама себе поставила (в крайнем случае их можно перенести, в отличие от остальных задач)</li>
            <li>Как только задача выполнена, пишу результат и закрываю задачу. Если закрываю задачу на клиента с планом, по необходимости сразу ставлю на него следующую задачу.</li>
            <li>Если в конце дня остались невыполненные задачи, чтобы они не стали просроченными, предпринимаю следующие действия. По задачам от руководства и системы отчитываюсь руководству о причинах невыполнения и прошу продлить срок выполнения задачи (изменить срок выполнения). Далее следую указаниям руководителя. По своим задачам: меняю им срок выполнения сама на удобный мне.</li>
          </ol> <br>Итог: в понедельник я начинаю работу и прохожу все 5 шагов алгоритма, в остальные дни выполняю только 3 и 4 и 5 шаги.
        </span>
        <span class="Subtitle">
          <a name="newClient" style="color: #5c6bc0">
            Заведение нового клиента или контактного лица
          </a>
        </span>
        <span>Для того, чтобы добавить нового клиента или контактное лицо, переходим в раздел "Списки".
        </span>
          <img alt="" src="https://251317.selcdn.ru/oboi.ru/BlogSrc/imagesA/image17.png">
        <span>В разделе "Списки" нужно выбрать нужный нам список. На данный момент действует 3 списка: Компании (клиенты), Контакты (контактные лица) и Все контакты и компании (и клиенты и контактные лица).
        </span>
          <img alt="" src="https://251317.selcdn.ru/oboi.ru/BlogSrc/imagesA/image21.png">
          <span>Чтобы добавить компанию, нужно отрыть список "Компании" и нажать на кнопку "Добавить компанию".</span>
          <img alt="" src="https://251317.selcdn.ru/oboi.ru/BlogSrc/imagesA/image62.png">
          <span>Чтобы контактное лицо - "Контакты" и нажать на кнопку "Добавить контакт". </span>
          <img alt="" src="https://251317.selcdn.ru/oboi.ru/BlogSrc/imagesA/image20.png">
          <span>Добавляем компанию. В открывшейся карточке по возможности нужно заполнить все поля. Сначала заполняем все данные о компании. Важными для заполнения полями являются: наименование клиента, телефон компании (например офиса или магазина), источник клиента, статус и категория, страна и город, полный адрес и описание. В описании указываем договоренности с клиентом, информацию о компании, которую полезно знать и иметь перед глазами. Полный адрес желательно указывать юридический или адрес офиса. E-mail, Web заполняются только если есть. По желанию заполняются поля округ и область.</span>
          <img alt="" src="https://251317.selcdn.ru/oboi.ru/BlogSrc/imagesA/image48.png">
          <span>Теперь добавляем контактное лицо, человека, с которым непосредственно будем общаться. Имя пишем максимально полное, по возможности с фамилией и отчеством. Важными для заполнения полями являются Телефон или E-mail (хотя бы что-то одно должно быть, но лучше, если будет и то и то), должность и роль (очень важно понимать с кем именно мы общаемся и какова его роль в принятии решения). Если человек отвечает за оплату - ставим пометку об этом, передвинув переключатель напротив соответствующего поля. В описании можно добавить важные сведения о человеке, о методах работы с ним. Если известна его дата рождения - добавляем. Вписываем добавочный номер клиента, если он есть + в поле телефон компании или телефон контакта должен быть заполнен тот телефон, для которого указывается добавочный. Если общаетесь с клиентом в чате - укажите в каком и напишите логин клиента.
        </span>
          <img alt="" src="https://251317.selcdn.ru/oboi.ru/BlogSrc/imagesA/image9.png">
          <span>Если у клиента несколько контактных лиц, то после сохранения всех уже заполненных данных можно завести аналогичным образом остальных. Нажимаем на кнопку "Добавить контакт" и заполняем форму для нового контактного лица.</span>
        <span class="Subtitle">
          <a name="funnels" style="color: #5c6bc0">
            Воронки продаж
          </a>
        </span>
        <span>На данный момент у нас существует две воронки продаж "Повторные продажи" и "Новые клиенты". Воронки продаж находятся в разделе "Сделки". По умолчанию открывается та воронка, с которой работали последний раз. Чтобы поменять воронку - кликаем на название и в открывшемся списке доступных выбираем нужную.
      </span>
        <img alt="" src="https://251317.selcdn.ru/oboi.ru/BlogSrc/imagesA/image6.png">
        <span><b>Рассмотрим воронку "Повторные продажи".</b><br>
          Это воронка для клиентов, которые уже с нами сотрудничают. 
        </span>
        <img alt="" src="https://251317.selcdn.ru/oboi.ru/BlogSrc/imagesA/image11.png">
        <span>Переход по этапам на данный момент осуществляется в ручном режиме путем перетаскивания сделки с этапа на этап. В будущем это будет в полуавтоматическом режиме.
      На данный момент в некоторых случаях при перетаскивании сделки с этапа на этап программа виснет, сделка не переносится. В таком случае можно действовать двумя способами:
        <ol>
          <li>Обновить страницу, провалиться в любую сделку и сразу же выйти. Сделки начнут перетаскиваться. Если обновить страницу еще раз - висяк повторится, нужно будет опять проделать все действия.</li>
          <li>Обновить страницу, провалиться в нужную сделку и из карточки перенести ее на нужный этап.</li>
        </ol>
      </span>
        <img alt="" src="https://251317.selcdn.ru/oboi.ru/BlogSrc/imagesA/image32.png">
        <span>Для того, чтобы завести планируемую отгрузку, открываем воронку, в этапе "План" есть кнопочка для быстрого добавления. Нажав ее, открываем форму для заполнения. Здесь заполняем название сделки, предполагаемую сумму заказа и клиента или контакт.<br> Например, я планирую отгрузить клиенту ИП Резник Сергей Александрович заказ на примерную сумму 100000 рублей.
      </span>
        <img alt="" src="https://251317.selcdn.ru/oboi.ru/BlogSrc/imagesA/image25.png">
        <span>Так же сделку можно создать напрямую из карточки клиента. Для этого переходим на вкладку "Сделки" и добавляем сделку. Осталось указать название, бюджет и выбрать воронку и этап, на который встанет сделка.
      </span>
        <img alt="" src="https://251317.selcdn.ru/oboi.ru/BlogSrc/imagesA/image33.png" >
        <span>Далее при выполнении условий перехода на следующий этап - перетаскиваем сделку в соответствующий столбец.<br> В программе предусмотрена сортировка сделок и отбор по заданным критериям. Сортировка возможна по четырем параметрам. Голубым цветом выделена текущая сортировка.
      </span>
        <img alt="" src="https://251317.selcdn.ru/oboi.ru/BlogSrc/imagesA/image63.png">
        <span>Фильтр организован так же, как и для задач. Есть быстрый и расширенный фильтр. Быстрым фильтром в один клик можно отобрать только свои открытые сделки, все сделки с задачами, все успешные или нереализованные. С помощью расширенного фильтра можно задать очень много критериев для отбора сделок. 4 основных критерия для отбора: отобрать все сделки созданные или закрытые за период, по определенному менеджеру или группе менеджеров, с задачами определенных типов или без задач, сделки по определенным этапам. Так же можно отфильтровать сделки по свойствам компаний или контактов. Например, можно отобрать сделки для компаний определенного города, из определенных источников, определенной категории.
      </span>
        <img alt="" src="https://251317.selcdn.ru/oboi.ru/BlogSrc/imagesA/image7.png">
        <span><b>Вторая воронка "Новые клиенты"</b> создана для проработки новых клиентов. Технически принцип работы с ней аналогичен. Замечу только, что прежде чем заводить сделку на нового клиента или контакт, клиента или контакт сначала нужно создать (для этого используй инструкцию для заведения клиентов).
      </span>
      <span class="Subtitle">
        <a name="sortClient" style="color: #5c6bc0">
          Поиск, сортировка и отбор клиентов. Раздел "Списки".
        </a>
      </span>
      <span>В разделе "Списки" клиентов и контакты можно отбирать по заданным условиям. Фильтр устроен таким же образом как и для задач и сделок. По умолчанию отображается полный список контактов или сделок. На скриншоте фильтр для компаний. Для контактов все аналогично.</span>
        <img alt="" src="https://251317.selcdn.ru/oboi.ru/BlogSrc/imagesA/image39.png">
        <span>Также есть возможность добавлять и удалять поля в таблице.
      </span>
        <img alt="" src="https://251317.selcdn.ru/oboi.ru/BlogSrc/imagesA/image35.png">
        <span>Дополнительные поля - поля, которые можно добавить. Добавляются путём перетаскивания нужного поля в таблицу, удаляются перетаскиванием из таблицы в область дополнительных полей. Также можно менять поля местами.<br>Данные в списках можно сортировать по возрастанию или по убыванию по любому выделенному полю.
      </span>
        <img alt="" src="https://251317.selcdn.ru/oboi.ru/BlogSrc/imagesA/image22.png">
        <span>Искать клиентов по списком можно по разным параметрам (имени, номеру телефона, e-mail, статусу и пр.), для этого вбиваем нужные данные в строку поиска.</span>
        <img alt="" src="https://251317.selcdn.ru/oboi.ru/BlogSrc/imagesA/image13.png">
      <span class="Subtitle">
        <a name="analytics" style="color: #5c6bc0">
          Аналитика
        </a>
      </span>
      <span><b>Список событий</b><br>
      Данный отчет позволяет посмотреть всю активность пользователя или группы пользователей за период времени (звонки, письма, задачи, примечания, работа со сделками, создание новых компаний и контактов).
      </span>
      <img alt="" src="https://251317.selcdn.ru/oboi.ru/BlogSrc/imagesA/image43.png">
      <span>По умолчанию показаны события по всем пользователям за все время работы, все события отсортированы по дате и времени. Для изменения параметров отчета можно воспользоваться фильтром.
      </span>
        <img alt="" src="https://251317.selcdn.ru/oboi.ru/BlogSrc/imagesA/image31.png">
        <span>Таким образом можно увидеть всю деятельность пользователя или группы пользователей. Например, у меня показаны все завершенные задачи и новые примечания менеджера за сегодня.
      </span>
      <img alt="" src="https://251317.selcdn.ru/oboi.ru/BlogSrc/imagesA/image4.png">
      <span><b>Отчет по звонкам</b><br>
    В отчете по звонкам представлен график, который поможет оценить эффективность как работы всего отдела продаж, так и каждого менеджера в частности. График можно просматривать в двух вариантах: по количеству звонков и по длительности звонков. Цифры на графике означают: входящие, исходящие, входящие + исходящие.
    </span>
      <img alt="" src="https://251317.selcdn.ru/oboi.ru/BlogSrc/imagesA/image42.png">
      <span>Под графиком приведен список самих звонков с возможностью прослушать и скачать их. Звонки в списке нужно отображать в трех вариантах: все звонки, входящие звонки, исходящие звонки.
    </span>
      <img alt="" src="https://251317.selcdn.ru/oboi.ru/BlogSrc/imagesA/image47.png">
      <span>Также есть возможность изменить параметры отчета с помощью фильтра. 
    </span>
      <img alt="" src="https://251317.selcdn.ru/oboi.ru/BlogSrc/imagesA/image38.png">
      <span>Также есть возможность быстро сформировать отчет по конкретному отделу.</span>
      <img alt="" src="https://251317.selcdn.ru/oboi.ru/BlogSrc/imagesA/image10.png">
    <span class="Subtitle">
      <a name="callCounter" style="color: #5c6bc0">
        Счетчики звонков
        </a>
    </span>
      <span>Счетчики звонков отражаются в карточке клиента на вкладке "Звонки". Отображаются входящие и исходящие звонки за месяц и неделю. Важно понимать, что считаются входящие звонки любой продолжительности, а исходящие только больше минуты. Для полной картины приведен список звонков за месяц. Также в поле Примечание можно написать комментарий, например описывающий причину небольшого количества звонков или продолжительности и пр. </span>
      <img alt="" src="https://251317.selcdn.ru/oboi.ru/BlogSrc/imagesA/image49.png">
      <span>Так же для удобства счетчики можно вывести в списке компаний. Таким образом можно увидеть общую картину звонков клиентам. С помощью фильтра можно увидеть статистику по менеджеру или группе менеджеров. Также с помощью списка будет проще определить, кому еще нужно позвонить. Добавить поля можно зайдя в настройки таблицы и перетащить нужные. </span>
      <img alt="" src="https://251317.selcdn.ru/oboi.ru/BlogSrc/imagesA/image15.png">
    <span class="Subtitle">
      <a name="plans" style="color: #5c6bc0">
        Занесение планов
      </a>
    </span>
    <span>Планы заносим в карточку компании в поле "План продаж".<br>
         Алгоритм действий следующий:
         <ol>
           <li>Заходим в меню "Списки" и выбираем список «Компании»</li>
           <li>Добавляем новый столбец «План продаж» в таблицу списка (если его еще нет, если есть - переходим к следующему пункту). Для этого заходим в настройки таблицы, из списка дополнительных полей выбираем необходимое и перетаскиваем его в таблицу на нужное место.</li>
         </ol>
    </span>
      <img alt="" src="https://251317.selcdn.ru/oboi.ru/BlogSrc/imagesA/image8.png">
      <span>3. Фильтруем клиентов по менеджеру. Для этого заходим в фильтр и в поле менеджер выбираем нужного.<br> 4. Заполняем планы прямо из списка, не заходя в карточку. Для этого наводим курсор мыши на поле для заполнения или редактирования и жмем на карандашик. В открывшееся окошко вписываем нужное значение и сохраняем.
    </span>
  <img alt="" src="https://251317.selcdn.ru/oboi.ru/BlogSrc/imagesA/image36.png">
  <span class="Subtitle">
    <a name="appeal" style="color: #5c6bc0">
      Обработка обращений
    </a>
  </span>
    <span>Все обращения с наших сайтов автоматически попадают в AMOCRM. Это происходит мгновенно после отправки нам заполненной формы с сайта.<br>Попадают они в специальную воронку "Обращения с сайтов". Воронка имеет два явных и два скрытых этапа.<br>Проход по воронке будет следующим: обращение падает на этап "Необработанные обращения", берем его в работу и двигаем на этап "В работе", прорабатываем и закрываем как успешное, либо как неуспешное.</span>
    <img alt="" src="https://251317.selcdn.ru/oboi.ru/BlogSrc/imagesA/image16.png">
    <span>Для каждого обращения создается сделка, также создается компания, которая прицепляется к сделке, и задача, которая ставится на новую компанию.<br>Время на обработку каждого обращения ограничено, поэтому задача ставится на 30 минут.<br>Сделка, задача и компания ставится на менеджера, ответственного за регион. Посмотреть ответственного можно в таблице распределения регионов, там же можно перейти к ее редактированию.
  </span>
    <img alt="" src="https://251317.selcdn.ru/oboi.ru/BlogSrc/imagesA/image5.png">
    <span>Для того, чтобы обработать обращение, нужно придерживаться следующего алгоритма:<br>
          1. Видим в воронке обращение, или видим сообщение у себя на почте о новой задаче, заходим в сделку, читаем комментарий к сделке, если он есть.
    </span>
    <img alt="" src="https://251317.selcdn.ru/oboi.ru/BlogSrc/imagesA/image59.png">
    <span>Если обращение не относится к обоям, а к логистике, или к закупкам - сделку сразу же закрываем (помещаем ее на один из скрытых этапов "Закрыто и не реализовано"</span>
    <img alt="" src="https://251317.selcdn.ru/oboi.ru/BlogSrc/imagesA/image41.png">
    <span>Если все в порядке - переносим сделку на этап "В работе".</span>
    <img alt="" src="https://251317.selcdn.ru/oboi.ru/BlogSrc/imagesA/image45.png">
    <span>2. Перенесли сделку на новый этап. Переходим в компанию.</span>
    <img alt="" src="https://251317.selcdn.ru/oboi.ru/BlogSrc/imagesA/image30.png">
    <span>4. На основании этих ответов формируем и отправляем КП клиенту на почту.<br> 5. Теперь необходимо закрыть задачу. Пишем реультат и закрываем.
  </span>
    <img alt="" src="https://251317.selcdn.ru/oboi.ru/BlogSrc/imagesA/image60.png">
    <span>6. Далее нужно решить, что сделать со сделкой. Если работать с клиентом не будете по тем или иным причинам - закрываем сделку как неуспешную и пишем в примечании причину закрытия. Если продолжаете прорабатывать клиента - закрываем сделку как успешную. 
  </span>
    <img alt="" src="https://251317.selcdn.ru/oboi.ru/BlogSrc/imagesA/image1.png">
    <span>Далее идем в воронку "Новые клиенты" и видим новую сделку на нашего клиента на этапе "Презентация". Сделки по обращениям помечены тегом "Обращение", так вы сможете отличить их от остальных.</span>
</div>
   <!--    <iframe src="https://docs.google.com/document/d/e/2PACX-1vQWWRd2LRtK5RDD0_yUL3ouhxjr5m0WrTB_PQDKaZ2gQ0Pq9OGrtFZbqb_xd_yKY33aGc1Dp-hgzw92/pub?embedded=true" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" :style="cardinfo"></iframe> -->

  </v-main>   
</template>

<script>


export default {
    name: 'InformationAmo',

    data: () => ({
      src: "https://251317.selcdn.ru/oboi.ru/brand/test.png",
      cardinfo: { 'background': '#F2F2F2',
                  'box-shadow': 'inset 1px 1px 2px rgba(255, 255, 255, 0.3), inset -1px -1px 2px rgba(219,219,219,0.5),-10px 10px 10px rgba(219, 219, 219, 0.2), 5px -5px 10px rgba(219, 219, 219, 0.2), -5px -5px 10px rgba(255, 255, 255, 0.9), 5px 5px 13px rgba(219, 219, 219, 0.9)',
                  'border-radius': '15px',
                  'border':'1px solid rgba(69,86,122,0.005)',
                  'margin-top':'30px'},
    }),
    }
</script>


<style scoped>

 iframe {
    width: 790px;
    height: 30500px;
    margin: 0 auto;
    display: block;
}

.Title {
  font-size: 50px;
  color: #616161;
  font-weight: 400;
  margin-top: 40px;
}

.Menu {
   margin: 40px auto;
   width: 1000px;
   font-size: 22px;
   color: #5c6bc0;
}
img {
  width: 70%;
  margin: 10px auto 20px;
  display: block;
}
span {
  padding: 30px 40px 0px 40px;
  display: block;
  font-size: 19px;
}

.Main {
  margin: 0 auto;
  width: 1000px;
  padding-bottom: 20px
}

.Subtitle {
  font-size: 30px;
  color: #5c6bc0;
  font-weight: 400;
}

a {
  text-decoration: none;
}
</style>

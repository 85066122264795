<template >
   <div>
    <v-app-bar tile dense flat color="white">
    <!--   <Login /> -->
    </v-app-bar>
  </div>
</template>

<script>

// import Login from "./Login"

  export default {
    name: 'Header',
    components: {
        // Login,
    },

    data: () => ({
      dialog: false,

    }),
    }
</script>



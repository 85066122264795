<template >
  <v-btn
    fab
    large
    icon
    class="Button"
    color="#ffffff"
    :href="href"
    :to="to"
  >
<!--   :to="to" -->
   <div>
      <slot name="icon"  />
    </div>
  </v-btn>
</template>

<script>

  export default {
    name: 'ButtonLink',
    props: {
      href: {
        type: String,
        required: true,
        default: "",
        validator: v => typeof v === "string"
      },

      to: {
      type: String,
      required: true,
      default: "",
      validator: v => typeof v === "string"
    },
    },

    data: () => ({}),
    }
</script>

<style scoped>
.Button {
  border: 3px solid #ffffff;
  height: 50px;
  width: 50px;
}

</style>

 



<template >
  <v-main >
    <div class="Main">
      <div class="text-center Title">
        <v-btn color="grey" icon x-large to="/">
          <v-icon>arrow_back</v-icon>
        </v-btn>
         На страничке собраны инструкции по логинам и паролям
      </div>
   
   
  <v-card :style="cardinfo">
      <div class="Blocks"> 
        <p class="text-h5 indigo--text text--lighten-1">Почта</p>
        <p class="grey--text text--darken-1">При трудоустройстве для вас создается корпоративная почта с доменным именем "mnogooboev".
            Например, ivanov@mnogooboev.ru.
            Для входа в почту в качестве логина используйте корпоративную почту, пароль можно запросить в ИТ-отделе, добавочный 120. 
        </p>
      </div>
    </v-card>
    
    <v-card :style="cardinfo">
      <div class="Blocks"> 
        <p class="text-h5 indigo--text text--lighten-1">Авторизация на сайте</p>
        <p class="grey--text text--darken-1">Для входа на сайт "Вкусненькое" необходимо авторизоваться. Логин - ваш номер телефона, пароль такой же, как от почты.
        </p>
        <p class="grey--text text--darken-1">Если пароль не подходит или вы его забыли, то восстанавливаем через кнопку "Хочу новый пароль". Если возникнут сложности - обращаемся в IT-отдел, добавочный 120.
        </p>
      </div>
    </v-card>
    
    <v-card :style="cardinfo">
      <div class="Blocks"> 
        <p class="text-h5 indigo--text text--lighten-1">Битрикс</p>
        <p class="grey--text text--darken-1">Логин для входа - ваша корпоративная почта. 
           Пароль - придумываете сами при первом входе. 
           Если забыли - восстанавливаем через кнопку "Забыли пароль?".
           Возникли трудности - обращаемся в ИТ-отдел, добавочный 120. 
        </p>
      </div>
    </v-card>

    <v-card :style="cardinfo"> 
      <div class="Blocks"> 
        <p class="text-h5 indigo--text text--lighten-1">amoCRM</p>
        <p class="grey--text text--darken-1">Логин для входа - ваша корпоративная почта. 
           Пароль - amocrm123321.  
        </p>
      </div>
    </v-card>

    <v-card :style="cardinfo">
      <div class="Blocks"> 
        <p class="text-h5 indigo--text text--lighten-1">1С</p>
         <p class="grey--text text--darken-1">Если вы пользуетесь 1С по ссылкам, то при переходе появится окошко "Требуется аутентификация". Логин - oboiru, пароль - oboirun.
        </p>
        <p class="grey--text text--darken-1">Далее необходимо авторизоваться непосредственно в 1С. Логин для входа - фамилия с инициалами. Например, ИвановПВ.
           Пароль - приходит в письме на корпоративную почту с темой "1С".  
        </p>
      </div>
    </v-card>
    
    <v-card :style="cardinfo"> 
      <div class="Blocks"> 
        <p class="text-h5 indigo--text text--lighten-1">Файлы и документы</p>
        <p class="grey--text text--darken-1">Логин для входа - корпоративная почта.
           Пароль такой же, как от почты. Если возникнут сложности - добавочный 120.  
        </p>
      </div>
    </v-card>    
  </div>
  </v-main>   
</template>

<script>

  export default {
    name: 'Guide',

    data: () => ({
        cardinfo: { 'background': '#F2F2F2',
                  'box-shadow': 'inset 1px 1px 2px rgba(255, 255, 255, 0.3), inset -1px -1px 2px rgba(219,219,219,0.5),-10px 10px 10px rgba(219, 219, 219, 0.2), 5px -5px 10px rgba(219, 219, 219, 0.2), -5px -5px 10px rgba(255, 255, 255, 0.9), 5px 5px 13px rgba(219, 219, 219, 0.9)',
                  'border-radius': '15px',
                  'border':'1px solid rgba(69,86,122,0.1)',
                  'margin-top':'30px'},
    }),
    }
</script>


<style scoped>
.Main {
  margin:  0 auto;
  width: 1000px;
  padding-bottom: 10px
}
.Blocks {
/*  margin-top: 30px;
  margin-bottom: 30px;*/
  padding: 30px;
}

.Title {
  font-size: 45px;
  color: #616161;
  font-weight: 400;
}
</style>

<template >
  <v-main >
    <div class="text-center Title">
      <v-btn color="grey" icon x-large to="/">
          <v-icon>arrow_back</v-icon>
      </v-btn>
        Инструкции по работе в Битрикс24
    </div>
     <!--   <iframe src="https://docs.google.com/document/d/e/2PACX-1vQZT-_7cEpjRhAoCe58d7qYdLnIiKNbcbYIsf5PxVXqXGeNjY5cBa9s3w9hcseW8t8aD6j87nNxEqTT/pub?embedded=true" scrolling="no" frameborder="0" marginheight="0" marginwidth="0" :style="cardinfo" name="shopContent"></iframe> -->
    <div class="Menu">
        <div><a href="#auto" style="color: #5c6bc0">Авторизация</a></div>
        <div><a href="#profile" style="color: #5c6bc0">Просмотр/редактирование профиля</a></div>
        <div><a href="#line" style="color: #5c6bc0">Запись в Живой ленте</a></div>
        <div><a href="#process" style="color: #5c6bc0">Запуск бизнес-процесса (БП)</a></div>
        <div><a href="#group" style="color: #5c6bc0">Работа в адаптационных группах</a></div>
      </div>
    <div class="Main" :style="cardinfo"> 
        <span>При трудоустройстве для вас создается корпоративная почта. Администратор Битрикс регистрирует вас и присылает ссылку для авторизации на почту. Если вы стажер, то ссылка приходит на личную почту.</span>

        <span class="Subtitle">
          <a name="auto" style="color: #5c6bc0">
            Авторизация
          </a>
        </span>
          <span>Переходим по ссылке. Придумываем и сохраняем пароль. Нажимаем кнопку “Регистрация”.</span>

          <img alt="" src="https://251317.selcdn.ru/oboi.ru/BlogSrc/imagesB/image12.png">
          <span>После регистрации вы попадете на страницу Битрикс 24 Паспорт. Из Битрикс24 паспорт необходимо перейти в Битрикс компании. Нажимаем кнопку “Перейти”.</span>

          <img alt="" src="https://251317.selcdn.ru/oboi.ru/BlogSrc/imagesB/image13.png">
          <span>Далее вы попадаете на главную страницу Битрикс компании.</span>

          <img alt="" src="https://251317.selcdn.ru/oboi.ru/BlogSrc/imagesB/image9.png">

        <span class="Subtitle">
          <a name="profile" style="color: #5c6bc0">
            Просмотр/редактирование профиля
          </a>
        </span>
          <span>После успешной регистрации необходимо заполнить свой профиль в Битрикс. Для этого кликаем на свое “Имя/Фамилию” и нажимаем кнопку “Профиль”.</span>

          <img alt="" src="https://251317.selcdn.ru/oboi.ru/BlogSrc/imagesB/image10.png">

          <span>В профиле некоторые поля уже будут заполнены. Например, ФИО, должность, почта и т.д. Необходимо будет дозаполнить профиль. Обязательные поля для заполнения: Фотография, ФИО, email (должна быть корпоративная почта. Если регистрировались под личной почтой, то ниже будут шаги, как изменить ее на корпоративную), Должность, Подразделение (отдел/группа к которой относитесь), Мобильный телефон, Внутренний телефон, Город, Начало работы, День рождения и Интересы. </span>

          <img alt="" src="https://251317.selcdn.ru/oboi.ru/BlogSrc/imagesB/image4.png">

          <span>На скриншоте звездочками отмечены обязательные поля для заполнения. Из карточки также можно посмотреть свои задачи, календарь, живая лента (записи, которые вы делали в живой ленте).</span>

          <span class="Subtitle">
            <a name="line" style="color: #5c6bc0">
              Сделать запись в Живой ленте
            </a>
          </span>
            <span>Для того, чтобы сделать запись в Живой ленте, необходимо нажать на поле “Написать сообщение…”</span>

            <img alt="" src="https://251317.selcdn.ru/oboi.ru/BlogSrc/imagesB/image14.png">

            <span>Откроется поле для редактирования.</span>

            <img alt="" src="https://251317.selcdn.ru/oboi.ru/BlogSrc/imagesB/image7.png">
          <span>
            <ol>
              <li>Прописываем тему, если необходимо.</li>
              <li>Пишем текст сообщения.</li>
              <li>Поле для редактирования текста (изменить шрифт, добавить нумерацию и т.д).</li>
              <li>Дополнительные функции (добавить тег, приложить файл, отметить человека и т.д)</li>
              <li>Выбор получателей. Автоматически получателями встают “Все сотрудники” компании. Чтобы выбрать других пользователей или группу, необходимо удалить “Все сотрудники”, нажав на крестик. Затем через кнопку “Добавить еще” выбираем необходимых получателей сообщения.</li>
              <li>Нажимаем кнопку “Отправить”  или “Отменить”, если хотим отменить сообщение.</li>
            </ol>    
            Из живой ленты также можно создать задачу, событие, опрос, важное сообщение.
          </span>

          <img alt="" src="https://251317.selcdn.ru/oboi.ru/BlogSrc/imagesB/image8.png">

        <span class="Subtitle">
          <a name="process" style="color: #5c6bc0">
            Запуск бизнес-процесса (БП)
          </a>
        </span>
          <span>Для того, чтобы запустить нужный бизнес-процесс необходимо перейти во вкладку “Еще” в живой ленте и выбрать “Процессы”.</span>

          <img alt="" src="https://251317.selcdn.ru/oboi.ru/BlogSrc/imagesB/image11.png">
          <span>Откроется список с процессами. Для запуска кликните по нужному.</span>

          <img alt="" src="https://251317.selcdn.ru/oboi.ru/BlogSrc/imagesB/image6.png">

          <span>Посмотреть все процессы, находящиеся на вас и начать выполнение, можно через левое боковое меню.</span>

          <img alt="" src="https://251317.selcdn.ru/oboi.ru/BlogSrc/imagesB/image5.png">

        <span class="Subtitle">
          <a name="group" style="color: #5c6bc0">
            Работа в адаптационных группах
          </a>
        </span>
          <span>Когда вы проходите стажировку, для вас создается адаптационная группа с задачами, которые необходимо выполнить. Общий вид представлен на скриншоте</span>

          <img alt="" src="https://251317.selcdn.ru/oboi.ru/BlogSrc/imagesB/image3.png">

          <span>Изначально все задачи находятся на этапе “Все задачи”.<br> Те задачи, которые вы планируете выполнить сегодня, необходимо перенести на этап “Выполняются”.<br>Когда берете задачу в работу обязательно необходимо включить учет времени.</span>

          <img alt="" src="https://251317.selcdn.ru/oboi.ru/BlogSrc/imagesB/image1.png">

          <span>После того, как задача будет выполнена, переносим задачу на этап “Сделаны” и нажимаем кнопку “Завершить”.</span>

          <img alt="" src="https://251317.selcdn.ru/oboi.ru/BlogSrc/imagesB/image2.png">

          <span>Далее задачу должен проверить постановщик задачи и завершить, в случае, если вы успешно справились с задачей, или отправить на доработку, если задача выполнена с неточностями. </span>
    </div>
  </v-main>   
</template>

<script>

export default {
  name: 'InformationBitrix',


    data: () => ({
      cardinfo: { 'background': '#F2F2F2',
                  'box-shadow': 'inset 1px 1px 2px rgba(255, 255, 255, 0.3), inset -1px -1px 2px rgba(219,219,219,0.5),-10px 10px 10px rgba(219, 219, 219, 0.2), 5px -5px 10px rgba(219, 219, 219, 0.2), -5px -5px 10px rgba(255, 255, 255, 0.9), 5px 5px 13px rgba(219, 219, 219, 0.9)',
                  'border-radius': '15px',
                  'border':'1px solid rgba(69,86,122,0.005)',
                  'margin-top':'30px'},
    }),
    }
</script>


<style scoped>

 iframe {
    width: 1000px;
    height: 8500px;
    margin: 0 auto;
    display: block;
    padding: 0px;
}

.Title{
  font-size: 45px;
  color: #616161;
  font-weight: 400;
  margin-top: 40px;
}

.Main {
  margin: 0 auto;
  width: 1000px;
  padding-bottom: 10px
}

img {
  width: 60%;
  margin: 10px auto 20px;
  display: block;
}

span {
  padding: 30px 40px 0px 40px;
  display: block;
  font-size: 19px;
}

.Subtitle {
  font-size: 30px;
  color: #5c6bc0;
  font-weight: 400;
}

.Menu {
   margin: 40px auto;
   width: 1000px;
   font-size: 22px;
   color: #5c6bc0;
}

a {
  text-decoration: none;
}
</style>

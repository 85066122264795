<template >
  <v-container >
   <!--  <Plug></Plug> -->
    <v-card
      class="ma-auto my-10"
      elevation="0"
      max-width="1500"
      flat
    >
      <!-- <BigBlockWithCards /> -->
      <Panel />
      <ForSales/>



      <!-- <InformationBlock /> -->
    </v-card>
    <Footer />
  
  </v-container>
</template>

<script>
// import Plug from "./Plug"
import Panel from "./PanelWithButton";
import Footer from "./FooterLink";
import ForSales from "./ForSales"
// import BigBlockWithCards from "./BigBlockWithCards";
// import InformationBlock from "./InformationBlock"


  export default {
    name: 'Home',

    components: {
      // Plug,
      Panel,
      Footer,
      ForSales,
      // BigBlockWithCards,
      // InformationBlock
    },

    data: () => ({}),

    }
</script>



<template>
  <v-row class="FooterPanel">
    <v-col
      md="2"
      cols="6"
      v-for="el in footer_link"
      :key="el.name"
      class="d-flex flex-column align-center"
    >
      <div class="text-left">
        <v-icon color="orange lighten-2" style="margin-top: -2px">{{
          el.icon
        }}</v-icon>
        <span class="BlockName">{{ el.name }}</span>
        <p v-for="j in el.components" :key="j.link_name" class="ComponentsName">
          <a :href="j.src" target="_blank" style="color: #8c8c8c">
            {{ j.link_name }}
          </a>
        </p>
      </div>
    </v-col>
    <v-col md="2" cols="6" class="d-flex flex-column align-center">
      <div class="text-left">
        <v-icon color="orange lighten-2" style="margin-top: -2px"
          >mdi-account-box-outline</v-icon
        >
        <span class="BlockName">{{ fullname }}</span>
        <p class="ComponentsName" style="color: #8c8c8c">{{ username }}</p>
        <p class="ComponentsName">
          <a
            @click="logout()"
            target="_blank"
            style="color: #8c8c8c; text-decoration: underline"
          >
            выход
          </a>
        </p>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import { mapState, mapMutations } from "vuex";
export default {
  name: "FooterLink",

  data: () => ({
    src: "https://251317.selcdn.ru/oboi.ru/brand/test.png",
    footer_link: [
      {
        name: "Наши сайты",
        components: [
          { link_name: "mnogooboev.ru", src: "https://mnogooboev.ru/" },
          { link_name: "decocode.ru", src: "https://decocode.ru/" },
          { link_name: "oboi.ru", src: "https://oboi.ru/" },
        ],
        icon: "language",
      },
      {
        name: "Поискать",
        components: [
          { link_name: "yandex.ru", src: "https://yandex.ru" },
          { link_name: "google.com", src: "https://www.google.ru/" },
        ],
        icon: "search",
      },
      {
        name: "ТК",
        components: [
          { link_name: "Мейджик", src: "https://magic-trans.ru/" },
          { link_name: "ПЭК", src: "https://pecom.ru/" },
          { link_name: "Главдоставка", src: "https://glav-dostavka.ru/" },
        ],
        icon: "local_shipping",
      },
      {
        name: "Карты",
        components: [
          { link_name: "Яндекс Карты", src: "https://yandex.ru/maps/" },
          { link_name: "Google карты", src: "https://www.google.ru/maps" },
          { link_name: "2GIS", src: "https://2gis.ru/" },
        ],
        icon: "place",
      },
    ],
  }),

  methods: {
    ...mapMutations(["login_on"]),
    logout() {
      localStorage.removeItem("access_token");
      localStorage.removeItem("refresh_token");
      this.login_on();
    },
  },
  computed: {
    ...mapState(["username", "fullname", "access"]),
  },
};
</script>

<style scoped>
.FooterPanel {
  border-color: #d9d9d9;
  border-width: 1px;
  border-style: solid none solid none;
  margin-bottom: 100px;
}

.BlockName {
  font-size: 17px;
  color: #535353;
  height: 85px;
}

.ComponentsName {
  text-align: left;
  margin-bottom: 0px;
}
</style>

<template >
  <v-main >
    <div class="text-center Title">
      <v-btn color="grey" icon x-large to="/">
        <v-icon>arrow_back</v-icon>
      </v-btn>
        Фабрики
    </div>

    <iframe src="https://docs.google.com/document/d/e/2PACX-1vTAGQifHW_7_-u7a580XsFdwtyZj0RWYm5R0NOV3fr7UTcydDdHekz24rjaN3cp-y_0qn1aELnRGdCm/pub?embedded=true" scrolling="no" frameborder="0" width="700px" height="1200" :style="cardinfo" marginheight="0" marginwidth="0"></iframe>

  </v-main>   
</template>

<script>


export default {
    name: 'Factories',

    data: () => ({
      src: "https://251317.selcdn.ru/oboi.ru/brand/test.png",
      cardinfo: { 'background': '#F2F2F2',
                  'box-shadow': 'inset 1px 1px 2px rgba(255, 255, 255, 0.3), inset -1px -1px 2px rgba(219,219,219,0.5),-10px 10px 10px rgba(219, 219, 219, 0.2), 5px -5px 10px rgba(219, 219, 219, 0.2), -5px -5px 10px rgba(255, 255, 255, 0.9), 5px 5px 13px rgba(219, 219, 219, 0.9)',
                  'border-radius': '15px',
                  'border':'1px solid rgba(69,86,122,0.005)',
                  'margin-top':'30px'},
    }),
    }
</script>


<style scoped>
 iframe {
    margin: 0 auto;
    display: block;
}

.Title {
  font-size: 50px;
  color: #616161;
  font-weight: 400;
  margin-top: 40px;
}

.Blocks {
/*  margin-top: 30px;
  margin-bottom: 30px;*/
  padding: 30px;
}

</style>

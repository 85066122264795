<template >
  <v-main >
      <div class="text-center Title">
        <v-btn color="grey" icon x-large to="/">
          <v-icon>arrow_back</v-icon>
        </v-btn>
        Список сотрудников
      </div>
      <iframe src="https://docs.google.com/spreadsheets/d/1jBGdc-s2IvntLV6w7irk5cvS0EzMhvsbmIco4tengFw/pubhtml?gid=201071489&single=true"  scrolling="no" frameborder="0" width="1300px" height="2400px" :style="cardinfo"></iframe>
  </v-main>   
</template>

<script>


export default {
  name: 'Weals',

  data: () => ({
      src: "https://251317.selcdn.ru/oboi.ru/brand/test.png",
      cardinfo: { 'background': '#F2F2F2',
                  'box-shadow': 'inset 1px 1px 2px rgba(255, 255, 255, 0.3), inset -1px -1px 2px rgba(219,219,219,0.5),-10px 10px 10px rgba(219, 219, 219, 0.2), 5px -5px 10px rgba(219, 219, 219, 0.2), -5px -5px 10px rgba(255, 255, 255, 0.9), 5px 5px 13px rgba(219, 219, 219, 0.9)',
                  'border-radius': '15px',
                  'border':'1px solid rgba(69,86,122,0.005)',
                  'margin-top':'30px'},
    }),
    }
</script>


<style scoped>
 iframe {
    margin: 0 auto;
    display: block;
}

.Title {
  font-size: 45px;
  color: #616161;
  font-weight: 400;
  margin-top: 40px;
  margin-bottom: 50px;
}

</style>

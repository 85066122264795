<template >
  
  <v-parallax src="https://251317.selcdn.ru/oboi.ru/BlogSrc/parallax.png" height="180" dark class="Parallax">
      <div class="ParallaxTitle">Вкусненькое</div>
      <p class="ParallaxSubTitle">легкий и радостный доступ к правильным полезностям </p>
    </v-parallax>

</template>

<script>


  export default {
    name: 'ParallaxCard',


    data: () => ({
      src: "https://251317.selcdn.ru/oboi.ru/BlogSrc/parallax.png",
    }),
    }
</script>


<style scoped>
.Parallax {
  text-align: center;
  margin-right: 20px;
  margin-left: 20px;
}

.ParallaxTitle {
  font-size: calc(2.5em + 1vw);
  color: #cfcfcf;
  font-weight: 450;
}

.ParallaxSubTitle {
  color: #cfcfcf;
  font-size: 20px;
}

</style>
